
















































































import api from "@/api/index"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  AuditFlowScope,
  DataDictionaryDtoPagedResultDto,
  FundDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "FundApplyList",
  components: {
    PagedTableView,
    AbSelect,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class FundApplyList extends Vue {
  typeList: any = [];
  childList: any = [];

  queryForm: any = {
    name: "",
    type: "",
    fundCategory: "",
  };
  detailId = 0;

  created() {
    this.fetchEnumType();
    this.fetchDataDictionary();
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundType",
      })
      .then((res) => {
        this.typeList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({ key: "FundCategory", maxResultCount: 1000 })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.childList = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.fundApply.getAll(params);
  }

  // 查看详情
  jumpDetail(row: FundDto) {
    this.$router.push({
      name: "fundMaintainApplyDetail",
      params: {
        id: row.id!.toString(),
      },
    });
    // this.detailId = row.id!
    // this.$router.push({
    //   name: "fundApplyDetail",
    //   params: { id: row.id!.toString() }
    // });
  }

  // 查看审核记录
  handleAuditFlow(row: any) {
    this.$router.push({
      name: "auditFlowDetail",
      params: {
        hostType: AuditFlowScope.FundApply,
        hostId: row.id,
        auditFlowId: row.auditFlowId,
      },
    });
  }
}
